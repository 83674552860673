.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*{
  font-family: 'DM Sans', sans-serif
}
.full-height {  
  height: 100%;
}

::-webkit-scrollbar-track
{
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
	background-color: transparent;
}

::-webkit-scrollbar
{
	width:8px;
	background-color: transparent;
}

::-webkit-scrollbar-thumb
{
	background-color:rgb(218,220,224);
	border: 0px solid #555555;
  border-radius: 100px;
}
::-webkit-scrollbar-thumb:hover
{
	background-color:rgb(192, 194, 196);
}
.nav-icon-box {
  min-width: 36px!important;
  padding-left: 5px!important;
}
.activities_time {
  color: #737B90;
  font-size:12px!important;
}
.MuiLinearProgress-colorPrimary {
  background-color: #43AB24 !important;
}
.MuiLinearProgress-barColorPrimary {
  background-color: #43AB24 !important;
}

/* slick slider custom css */
.slick-slider {
  height: 100%;
}
.slick-list {
  height: 100%;
}
.slick-track {
  height: 100%;
}
.slick-slide {
  height: 100%;
}
.slick-slide div {
  height: 100%;
}

/* viewer custom css */
.viewerContainer video {
  height: 100%;
  width: 100%;
}
.video-container .loading-container {
  position: absolute;
}
